.benefits-page {
  width: 100vw;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 50px;
}

.benefits-header-container {
  margin-left: 80px;
}

.benefits-container {
  padding: 80px;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 80px;
}

.benefit {
  align-items: flex-start;
  gap: 20px;
  max-width: 40vw;
}

.benefit-text-container {
  gap: 5px;
  align-items: flex-start;
}

.benefit-header {
  color: #009883;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.benefit-text {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width: 610px) {
  .benefits-page {
    gap: 30px;
  }

  .benefits-header-container {
    margin-left: 10px;
  }

  .benefits-container {
    padding: 40px;
    gap: 30px;
  }

  .benefit {
    max-width: 100vw;
  }
}
