.about-page {
  width: 100vw;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 50px;
}

.section-header-container {
  margin: 30px 0px 0px 50px;
}

.section-header {
  margin: 0px;
  color: #fff;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 55px;
}

.section-header-image {
  width: 64px;
  align-self: flex-start;
}

.about-us-body-container {
  margin-top: 50px;
  padding-left: 100px;
  gap: 250px;
  justify-content: flex-start;
  width: -webkit-fill-available;
  flex-wrap: wrap;
}

.about-us-image {
  width: 557px;
}

.about-us-description-container {
  margin-right: 50px;
  width: 520px;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
  gap: 12px;
}

.about-us-description-header {
  color: #009883;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  margin: 0px;
}

.about-us-description {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 52px;
}

.about-us-button-container {
  display: flex;
  padding: 15.563px 23.345px;
  align-items: center;
  gap: 9.727px;
  border-radius: 1197.408px;
  background: var(--foundation-green-normal-hover, #009883);
  cursor: pointer;
}

.about-us-button-text {
  color: #fff;
  font-size: 19.454px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.about-us-feature {
  gap: 24px;
}

.about-us-header-container {
  margin-left: 50px;
}

.about-us-feature-container {
  gap: 70px;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 70px;
  flex-wrap: wrap;
}

.about-us-feature-image {
  width: 113px;
  border-radius: 100%;
  box-shadow: 0px 4px 2px 0px rgba(18, 18, 18, 0.5);
}

.about-us-feature-header {
  color: #009883;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 130px;
}

@media screen and (max-width: 1465px) {
  .about-us-body-container {
    gap: 70px;
  }

  .about-page {
    gap: 50px;
  }

  .about-us-image {
    width: 400px;
  }

  .about-us-feature {
    width: 100px;
  }

  .about-us-feature-image {
    width: 80px;
  }
}

@media screen and (max-width: 1095px) {
  .about-us-body-container {
    gap: 50px;
  }

  .about-us-image {
    width: 400px;
  }

  .about-us-description {
    font-size: 16px;
  }

  .about-us-description-header {
    font-size: 20px;
  }

  .about-us-feature {
    width: 100px;
  }

  .about-us-feature-image {
    width: 80px;
  }

  .about-us-button-container {
    padding: 15px 23px;
  }

  .about-us-button-text {
    font-size: 16px;
  }
}

@media screen and (max-width: 1000px) {
  .section-header {
    font-size: 48px;
  }

  .about-us-body-container {
    padding-left: 20px;
    gap: 20px;
    justify-content: center;
  }

  .about-us-image {
    width: 80%;
  }

  .about-us-description-container {
    margin-right: 20px;
    width: 100%;
  }

  .about-us-description-header {
    font-size: 20px;
  }

  .about-us-description {
    font-size: 16px;
  }

  .about-us-feature-container {
    gap: 20px;
  }

  .about-us-feature-header {
    font-size: 16px;
    width: 100%;
  }

  .about-us-feature {
    width: 110px;
  }

  .about-us-feature-image {
    width: 80px;
  }

  .about-us-button-container {
    align-self: center;
  }
}

@media screen and (max-width: 610px) {
  .section-header-container {
    margin-left: 10px;
  }

  .section-header {
    font-size: 40px;
  }

  .section-header-image {
    width: 40px;
  }

  .about-page {
    gap: 30px;
  }

  .about-us-body-container {
    padding-left: 20px;
    gap: 40px;
    justify-content: center;
  }

  .about-us-image {
    width: 80%;
  }

  .about-us-description-container {
    margin-right: 20px;
    width: 100%;
  }

  .about-us-description-header {
    font-size: 20px;
  }

  .about-us-description {
    font-size: 16px;
  }

  .about-us-feature-container {
    gap: 20px;
  }

  .about-us-feature-header {
    font-size: 16px;
    width: 100%;
  }

  .about-us-feature {
    width: 110px;
  }

  .about-us-feature-image {
    width: 80px;
  }

  .about-us-button-container {
    align-self: center;
  }
}
