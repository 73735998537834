.hero-container {
  width: 100vw;
  height: 100vh;
}

.hero-content-container {
  height: 100%;
  width: -webkit-fill-available;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0px 50px 40px;
}

.hero-header-container {
  align-items: flex-start;
}

.hero-header {
  color: rgba(255, 255, 255, 0.8);
  font-size: 56px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.hero-button-container {
  gap: 20px;
  margin-top: 24px;
  margin-bottom: 20px;
  display: flex;
  padding: 14.778px 22.167px;
  align-items: center;
  gap: 9.236px;
  border-radius: 1137px;
  border: 1px solid rgba(222, 222, 222, 0.25);
  background: rgba(38, 37, 37, 0.32);
  backdrop-filter: blur(6px);
  cursor: pointer;
}

.hero-button-text {
  color: #fff;
  font-size: 18.473px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.hero-description-container {
  height: -webkit-fill-available;
  justify-content: space-between;
}

.hero-description {
  color: #fff;
  text-align: justify;
  font-size: 20px;
  width: 417px;
  padding: 45px;
  font-weight: 300;
  line-height: 26px;
  margin-top: 100px;
  border-radius: 713px;
  background: rgba(0, 0, 0, 0.1);
  /* filter: blur(71.21636962890625px); */
}

.hero-image {
  align-self: flex-end;
  margin-bottom: 20px;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .hero-content-container {
    padding: 0px 20px 40px;
  }

  .hero-description {
    padding: 22px;
  }
}

@media screen and (max-width: 790px) {
  .hero-header {
    font-size: 40px;
  }

  .hero-description {
    width: 20vw;
    font-size: 18px;
    margin-top: 30px;
    padding: 20px;
  }
}

@media screen and (max-width: 545px) {
  .hero-description {
    width: 30vw;
    font-size: 16px;
    padding: 10px;
  }
}

@media screen and (max-width: 400px) {
  .hero-description {
    font-size: 14px;
    padding: 5px;
  }
}
