.contact-page {
  width: 100vw;
  align-items: flex-start;
  /* margin-bottom: 50px; */
  margin-top: 50px;
}

.contact-us-container {
  flex-wrap: wrap;
}

.contact-us-left {
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0.4) 100%
    ),
    url("../../assets/images/contact-us.jpg"), lightgray 50% / cover no-repeat;
  background-size: cover;
  padding: 60px 80px 60px 100px;
  align-items: flex-start;
  width: 630px;
}

.contact-us-left-header {
  color: var(--General-Light, #fff);
  font-size: 27.031px;
  font-style: normal;
  font-weight: 600;
  line-height: 36.685px;
}

.contact-us-left-text {
  color: var(--General-Light, #fff);
  font-size: 17.377px;
  font-style: normal;
  font-weight: 500;
  line-height: 36.685px;
  margin-top: 8px;
}

.contact-us-info-container {
  gap: 25px;
  width: 430px;
  align-items: flex-start;
  margin-top: 45px;
}

.contact-us-info-text {
  color: var(--General-Light, #fff);
  font-size: 19.308px;
  font-style: normal;
  font-weight: 400;
  line-height: 36.685px;
}

.contact-us-info {
  gap: 8px;
}

.contact-us-right {
  padding: 50px;
}

.contact-us-form {
  width: 550.277px;
  align-items: flex-start;
  gap: 28px;
}

.contact-us-form-header {
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 19.308px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.377px;
  margin-bottom: 10px;
}

.contact-us-form-input-container {
  gap: 7.7px;
  width: 100%;
  align-items: flex-start;
}

.contact-us-form-label {
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 11.585px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.377px;
}

.contact-us-form-input {
  background: none;
  border: none;
  border-bottom: 1px solid #e0e0e0;
  color: #fff;
  width: 100%;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 15.446px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#message {
  height: 1.2em;
  resize: none;
}

.contact-us-form-input:focus {
  outline: none;
  border-bottom: 1px solid #008774;
}

.contact-us-form-input:focus + .contact-us-form-label {
  color: #008774;
}

.contact-us-form-submit {
  border-radius: 35.72px;
  border: none;
  background: #00a991;
  display: flex;
  width: 100%;
  padding: 17.377px 32.824px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--General-Light, #fff);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 15.446px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 10px;
}

div[role="alert"] {
  background: rgb(178, 239, 178);
  margin-top: 50px;
}

@media screen and (max-width: 1520px) {
  .contact-us-left {
    width: 500px;
  }
}

@media screen and (max-width: 1390px) {
  .contact-us-left {
    width: 400px;
  }

  .contact-us-form {
    width: 400px;
  }

  .contact-us-container {
    gap: 50px;
  }
}

@media screen and (max-width: 1200px) {
  .contact-us-container {
    justify-content: center;
  }

  .contact-us-left {
    width: 100%;
  }

  .contact-us-right {
    width: 100%;
  }

  .contact-us-form {
    width: 100%;
  }
}

@media screen and (max-width: 671px) {
  .contact-us-left {
    padding: 50px;
  }

  .contact-us-left-header {
    font-size: 23.437px;
  }

  .contact-us-left-text {
    font-size: 15.446px;
  }

  .contact-us-info-container {
    width: 100%;
  }

  .contact-us-info-text {
    font-size: 15.446px;
  }

  .contact-us-form {
    width: 100%;
  }

  .contact-us-form-header {
    font-size: 15.446px;
  }

  .contact-us-form-label {
    font-size: 11.585px;
  }

  .contact-us-form-input {
    font-size: 15.446px;
  }

  .contact-us-form-submit {
    font-size: 15.446px;
  }
}
