.footer-container {
  width: 100vw;
  justify-content: space-around;
  align-items: flex-start;
  padding-bottom: 50px;
  flex-wrap: wrap;
  background-color: black;
  padding-top: 60px;
}

.footer-description-container {
  align-items: flex-start;
  gap: 40px;
}

.footer-description-header {
  color: var(--Base-Light, #fff);
  font-family: Sarina;
  font-size: 31px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: -0.93px;
  opacity: 0.5;
  margin: 0px;
}

.footer-description {
  color: var(--Base-Light, #fff);
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.44px;
  opacity: 0.5;
  width: 455px;
  flex-shrink: 0;
  margin-bottom: 20px;
}

.footer-socials-container {
  gap: 5px;
  align-items: flex-start;
  justify-content: flex-start;
}

.footer-social-links {
  gap: 20px;
}

.footer-social-link {
  cursor: pointer;
  aspect-ratio: 24/24;
  width: 24px;
}

.footer-social-text {
  color: var(--General-Light, #fff);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 36.685px; /* 190% */
}

.footer-services-container {
  gap: 55px;
  align-items: flex-start;
}

.footer-section-header {
  color: var(--Base-Light, #fff);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.96px;
  opacity: 0.5;
  margin: 0px;
}

.footer-services {
  align-items: flex-start;
  gap: 8px;
}

.footer-service {
  color: var(--Base-Light, #fff);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.72px;
  margin: 0px;
}

.footer-location-container {
  gap: 33px;
  align-items: flex-start;
}

.footer-location-map {
  border: 0;
}

@media screen and (max-width: 1000px) {
  .footer-description-container {
    padding-left: 10px;
  }

  .footer-description-header {
    font-size: 24px;
  }

  .footer-description {
    font-size: 18px;
    width: 300px;
  }

  .footer-social-text {
    font-size: 18px;
  }

  .footer-section-header {
    font-size: 20px;
  }

  .footer-service {
    font-size: 15px;
  }

  .footer-social-link {
    width: 35px;
  }
}

@media screen and (max-width: 600px) {
  .footer-container {
    /* padding: 0px 20px; */
    gap: 30px;
  }

  .footer-description-container {
    gap: 20px;
    padding-bottom: 40px;
  }

  .footer-description {
    width: 100%;
  }

  .footer-services-container {
    gap: 20px;
  }

  .footer-location-container {
    gap: 20px;
  }
}

@media screen and (max-width: 420px) {
  .footer-container {
    padding-right: 0px;
  }

  .footer-description-header {
    font-size: 18px;
  }

  .footer-description {
    font-size: 16px;
  }

  .footer-social-text {
    font-size: 16px;
  }

  .footer-section-header {
    font-size: 16px;
  }

  .footer-service {
    font-size: 12px;
  }

  iframe {
    aspect-ratio: 200 / 300;
    width: 150px;
  }
}

@media screen and (max-width: 320px) {
  .footer-container {
    align-items: flex-start;
    justify-content: flex-start;
  }
}
