.products-page {
  width: 100vw;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 50px;
}

.products-container {
  margin-top: 50px;
  padding: 0px 80px;
  gap: 30px;
  justify-content: flex-start;
  width: -webkit-fill-available;
  flex-wrap: wrap;
  justify-content: space-around;
}

.products-container-header {
  margin-left: 80px;
}

.product {
  border-radius: 15px;
  box-shadow: 0px 1.867px 3.733px 0px #fff;
  display: flex;
  aspect-ratio: 373 / 454;
  width: 200px;
  align-items: flex-start;
  margin-top: 35px;
  box-shadow: 0px 1.867px 4px 0px rgba(0, 0, 0, 0.5);
}

.product-image {
  width: 100%;
  border-radius: 15px 15px 0px 0px;
  object-fit: cover;
}

.image-container {
  width: 100%; /* Adjust container width as needed */
  height: 100%; /* Adjust container height as needed */
  overflow: hidden;
}

.image-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-wrapper img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.product-header {
  display: flex;
  padding: 14.932px 22.399px;
  flex-direction: column;
  align-items: center;
  gap: 7.466px;
  align-self: stretch;
  background: #00a991;
  color: #ffffff;
  border-radius: 0px 0px 15px 15px;
  font-family: "Nunito Sans";
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media screen and (max-width: 610px) {
  .products-container-header {
    margin-left: 10px;
  }
}
