@font-face {
  font-family: "Sarina";
  src: url("./assets/fonts/Sarina-Regular.ttf");
}

@font-face {
  font-family: "Plus-Jakarta-Sans";
  src: url("./assets/fonts/PlusJakartaSans.ttf");
}

@font-face {
  font-family: "Nunito Sans";
  src: url("./assets/fonts/Nunito-Sans.ttf");
}

a {
  text-decoration: none;
  color: white;
}

body {
  margin: 0;
  padding: 0;
  background: #0a0a0a;
  font-family: "Plus-Jakarta-Sans";
  overflow-x: hidden;
}

.flex-container-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
}

.flex-container-column {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
}

.flex-item {
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  margin: 0px;
}
