.fixed-navigation-container {
  position: fixed;
  display: none;
  bottom: 0;
  gap: 23px;
  background: rgba(38, 37, 37, 0.32);
  backdrop-filter: blur(6px);
  padding: 6px 12px;
  border-radius: 20px 20px 0 0;
  text-align: center;
}

.fixed-navigation-link {
  font-size: 10px;
}

@media screen and (max-width: 890px) {
  .fixed-navigation-container {
    display: flex;
  }
}
