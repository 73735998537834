.navbar {
  width: -webkit-fill-available;
  padding: 0px 50px;
  margin: 30px 0px;
  justify-content: space-between;
}

.navbar-logo-container {
  gap: 35px;
}

.navbar-logo {
  width: 52px;
}

.navbar-title {
  color: #d9d9d9;
  font-family: "Sarina";
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.navbar-links-container {
  gap: 23px;
}

.navbar-link {
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 11.3px 15px;
  border-radius: 5px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.navbar-link:hover {
  background: rgba(255, 255, 255, 0.12);
}

.contact-us {
  border-radius: 25.638px;
  border: 0.95px solid #fff;
  background: #fff;
  color: #000;
}

.contact-us:hover {
  background: #fff;
}

@media screen and (max-width: 1200px) {
  .navbar {
    padding: 0px 20px;
  }

  .navbar-logo-container {
    gap: 20px;
  }

  .navbar-links-container {
    gap: 15px;
  }

  .navbar-title {
    font-size: 30px;
  }

  .navbar-logo {
    width: 40px;
  }
}

@media screen and (max-width: 890px) {
  .navbar-links-container {
    display: none;
  }

  .navbar-logo-container {
    gap: 20px;
  }

  .navbar-title {
    font-size: 25px;
  }

  .navbar-logo {
    width: 35px;
  }
}
